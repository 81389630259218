// export const $SERVER = "http://localhost:5000";

export const $SERVER = "https://le-1755.herokuapp.com";

export const GOOGLE_API_KEY = "AIzaSyAZaWwVgn5z9gNPy0cbweEVGUeWwva5GGM";

export const COLOR_1755 = "#2b2b29";

export const COLOR_ACARCIARA = "#556341";

export const COMPRESSION_QUALITY = 40;
